/**
** JAVASCRIPTS
** Name: Header
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-header')) {


    /**
    ** Variables
    ****************************************/

    const $html   = document.querySelector('html');
    const $toggle = document.querySelector('.js-header-toggle');


    /**
    ** Events
    ****************************************/

    $toggle.addEventListener('click', (event)=>{
      event.preventDefault();
      $html.classList.toggle('nav-active');
    });


  }

})();
