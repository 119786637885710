/**
** JAVASCRIPTS
** Name: Navigation
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-navigation')) {


    /**
    ** Variables
    ****************************************/

    const $navigation = document.querySelector('.js-navigation');
    const $items      = document.querySelectorAll('.js-navigation-item');
    const $links      = document.querySelectorAll('.js-navigation-link');


    /**
    ** Events
    ****************************************/

    $links.forEach(function($link) {

      $link.addEventListener('click', (event)=>{
        event.preventDefault();

        const $item = $link.closest('.js-navigation-item');

        $items.forEach(function($currentItem) {
          if($currentItem != $item) {
            $currentItem.classList.remove('is-active');
          }
        });

        $item.classList.toggle('is-active');
      });
    });


    /**
    ** Document
    ****************************************/

    document.addEventListener('click', (e)=>{
      e = e || window.event;
      var target = e.target || e.srcElement;

      if(!target.closest('.js-navigation-item')) {
        $items.forEach(function($currentItem) {
          $currentItem.classList.remove('is-active');
        });
      }
    }, false);


  }

})();
