/**
** JAVASCRIPTS
** Name: Tabs
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-tabs')) {


    /**
    ** Variables
    ****************************************/

    const $html    = document.querySelector('html');
    const $tabs    = document.querySelector('.js-tabs');
    const $buttons = document.querySelectorAll('.js-tabs-button');
    const $targets = document.querySelectorAll('.js-tabs-target');


    if($buttons.length > 0 && $targets.length > 0) {


      /**
      ** Init
      ****************************************/

      $buttons[0].classList.add('is-active');

      $targets.forEach(function($target, index) {
        if(index > 0) {
        $target.classList.add('u-hidden');
        }
      });


      /**
      ** Events
      ****************************************/

      $buttons.forEach(function($button) {
        $button.addEventListener('click', (event)=>{
          event.preventDefault();

          const $target = document.querySelector('.js-tabs-target[data-id="' + $button.dataset.target + '"]');

          if(!$button.classList.contains('is-active')) {

            $buttons.forEach(function($currentButton) {
              if($currentButton != $button) {
                $currentButton.classList.remove('is-active');
              }
            });

            $targets.forEach(function($currentTarget) {
              if($currentTarget != $target) {
                $currentTarget.classList.add('u-hidden');
              }
            });

            $button.classList.add('is-active');
            $target.classList.remove('u-hidden');

          }
        });
      });


    }
  }

})();
